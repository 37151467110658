.m-parameter-hint {
    display: inline-block;
    margin-top: 5px;
    color: var(--theme-color);
    transition: opacity 0.8s;
    font-size: var(--font-size-l4);
    font-weight: 200;
}

@media (max-width: 600px) {
    .m-parameter-hint {
        font-size: var(--font-size-l4-mobile);
    }
}