:root {
    --padding-l1: 32px;
    --padding-l1-mobile: 18px;
    --padding-l2: 16px;
    --padding-l2-mobile: 15px;
    --padding-l3: 14px;
    --padding-l3-mobile: 12px;
    --padding-l4: 7px;
    --padding-l4-mobile: 6px;

    --font-size-l0: 24px;
    --font-size-l0-mobile: 22px;
    --font-size-l1: 20px;
    --font-size-l1-mobile: 19px;
    --font-size-l2: 18px;
    --font-size-l2-mobile: 17px;
    --font-size-l3: 15px;
    --font-size-l3-mobile: 14px;
    --font-size-l4: 13px;
    --font-size-l4-mobile: 12px;

    --lighter-gray: rgb(180, 180, 180);
    --light-gray: rgb(140, 140, 140);
    --gray: rgb(80, 80, 80);
    --dark-gray: rgb(70, 70, 70);
    --darker-gray: rgb(65, 65, 65);
    --dark: rgb(60, 60, 60);
    --darker: rgb(42, 42, 42);
    --darkest: rgb(22, 22, 22);
    --deep-black: rgb(0, 0, 0);
    --lightest: rgb(250, 250, 250);
    --light-1: rgb(240, 240, 240);
    --theme-color: rgb(255, 164, 25);

    --signal-color-red: rgb(255, 30, 30);
    --signal-color-green: rgb(60, 180, 85);

    --bg-transition-duration: 0.15s;

    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}