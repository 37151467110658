@font-face {
    font-family: 'Luckiest Guy';
    src: url('./../../assets/fonts/LuckiestGuy-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.slidari-rush-highlight-title {
    max-width: 1000px;
    width: 100%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: .02rem;
}

.slidari-rush-highlight-title div {
    display: inline-block;
    background: var(--theme-color);
    padding: 14px 14px 9px;
    color: rgb(24, 24, 24);
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 22px;
    white-space: nowrap;
    border: 1px solid var(--darker);
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    border-radius: 2px;
}

@media (max-width: 600px) {
    .slidari-rush-highlight-title div {
        padding: 12px 12px 7px;
        font-size: 18px;
    }
}