.m-data-editor-panel {
    width: 100%;
    max-width: 1400px;
    margin-top: 40px;
}

.m-data-editor-panel .action-area:not(:last-child) {
    margin-bottom: var(--padding-l3);
}

.m-data-editor-panel .action-bar-header {
    font-weight: 500;
    display: inline-block;
    margin-bottom: 1px;
}

.m-data-editor-panel .auto-column {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.m-data-editor-panel-content-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.m-data-editor-panel-content-container .m-button {
    width: 100%;
}

.m-data-editor-panel-fields-container {
    display: block;
    flex: 3;
}

.m-data-editor-panel-actions-container {
    display: block;
    flex: 2;
}

@media (max-width: 600px) {
    .m-data-editor-panel-content-container {
        display: block;
    }

    .m-data-editor-panel-fields-container {
        flex: 1;
    }

    .m-data-editor-panel-actions-container {
        flex: 1;
        margin-top: 22px;
    }
}