.m-page-content-wrapper {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.m-limited-content {
    display: block;
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.m-page-content {
    margin: 100px var(--padding-l1) 50px;
    flex-grow: 1;
}

.m-page-content.no-margin {
    margin-top: 70px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.m-page-content.without-header {
    margin-top: var(--padding-l1);
}

@media (max-width: 680px) {
    .m-page-content {
        margin: 78px var(--padding-l1-mobile) 50px;
    }

    .m-page-content.no-margin {
        margin-top: 56px;
    }

    .m-page-content.without-header {
        margin-top: var(--padding-l1-mobile);
    }
}