.m-input input {
}

.m-input > input[type="checkbox"]:first-child + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-weight: 300;
    font-size: var(--font-size-l3);
    white-space: nowrap;
}

.m-input > input[type="checkbox"]:first-child + label a {
    text-decoration: none;
    font-weight: 500;
    font-size: var(--font-size-l3);
    white-space: nowrap;
}

.m-input > input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 1;
}

.m-input > input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
    background-color: var(--darker);
    border: 1px solid var(--dark);
    border-radius: 2px;
    transition: border-color 0.2s, background-color 0.2s;
}

.m-input > input[type="checkbox"]:checked + label::before {
    background-color: var(--dark);
    border-color: var(--light-1);
}

.m-input > input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: 6px;
    border-radius: 1px;
    font-size: 16px;
    background-color: var(--light-1);
    border-color: var(--light-1);
}

.m-input > input[type="checkbox"] + label:not(:disabled):hover::before {
    border-color: var(--light-1);
}

.m-input > input[type="checkbox"]:disabled + label::before {
    background-color: var(--lighter-gray);
    border-color: var(--gray);
}

.m-input > input[type="checkbox"]:disabled + label::after {
    background-color: var(--gray);
    border-color: var(--gray);
}

@media (max-width: 600px) {
    .m-input > input[type="checkbox"]:first-child + label {
        font-size: var(--font-size-l3-mobile);
    }

    .m-input > input[type="checkbox"]:first-child + label a {
        font-size: var(--font-size-l3-mobile);
    }
}