.m-push-message {
    padding: 8px 8px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 160px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.3);
    transition: opacity 0.38s ease;
}

.m-push-message.fade-out {
    opacity: 0;
}

.m-push-message .m-hint {
    box-shadow: none;
}

.m-push-message p:nth-child(1) {
    font-weight: 400;
}

.m-push-message p:nth-child(2) {
    font-weight: 300;
}

.m-push-message button {
    background: none;
    border: none;
    font-size: 10px;
    cursor: pointer;
    margin-left: 10px;
    right: 0;
}

.m-push-message.nice {
    background-color: var(--signal-color-green);
    color: var(--dark);
    border-color: var(--dark);
}

.m-push-message.warning {
    background-color: var(--theme-color);
    color: var(--dark);
    border-color: var(--dark);
}

.m-push-message.error {
    background-color: var(--signal-color-red);
    color: var(--light-1);
    border-color: var(--light-1);
}