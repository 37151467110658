.m-button {
    height: 40px;
    min-width: 100px;
    padding: 4px 14px;
    background-color: var(--gray);
    color: var(--light-1);
    border: solid 1px var(--dark-gray);
    border-radius: 2px;
    font-size: var(--font-size-l3);
    font-family: 'JosefinSans', sans-serif;
    transition: border-color var(--bg-transition-duration), color var(--bg-transition-duration), background-color var(--bg-transition-duration);
    font-weight: 400;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 0;
}

.m-button:disabled {
    color: var(--light-gray);
    cursor: not-allowed;
    font-weight: 300;
}

.m-button.transparent {
    background-color: rgba(0, 0, 0, 0.1);
    border: solid 1px var(--light-1);
    backdrop-filter: blur(6px);
}

.m-button:hover {
    white-space: normal;
    text-overflow: clip;
}

.m-button:hover:not([disabled]) {
    background-color: var(--dark);
    color: var(--lightest);
    cursor: pointer;
}

.m-button:hover:not([disabled]):not(.transparent) {
    border: solid 1px var(--lightest);
}

.m-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba(38, 38, 38, 0.1), transparent);
    transform: scaleX(0.6);
    z-index: -1;
    transition: left 1.5s ease-in-out;
}

.m-button:hover:not([disabled])::before {
    left: 100%;
}

.m-button:hover:not([disabled])::before {
    left: 100%;
    animation: left 1.5s ease-in-out;
}

@media (max-width: 600px) {
    .m-button {
        font-size: var(--font-size-l3-mobile);
    }
}