.game-panel {
    max-width: 780px !important;
}

.game-panel-title {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: baseline;
}

.game-panel-title h1 {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    font-size: 26px;
}

.game-panel-title span {
    color: var(--light-gray);
    font-size: var(--font-size-l4);
}

.game-panel .keywords {
    display: flex;
    gap: 18px;
    font-weight: 200;
    align-items: center;
    flex-direction: row;
    margin-bottom: 16px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.game-panel .content {
    padding: var(--padding-l2);
}

.game-panel .m-button {
    width: 140px;
}

@media (max-width: 680px) {
    .game-panel hr {
        margin-top: 11px !important;
        margin-bottom: 11px !important;
    }

    .game-panel-title h1 {
        font-size: 24px;
    }

    .game-panel-title span {
        color: var(--light-gray);
        font-size: var(--font-size-l4-mobile);
    }

    .game-panel .keywords {
        margin-bottom: 13px;
    }
}