.m-video {
    position: relative;
    width: 100%; /* Container nimmt die volle Breite des übergeordneten Elements ein */
    padding-top: 56.25%; /* Standard für 16:9 Seitenverhältnis (100% / 16 * 9) */
    overflow: hidden;
}

.m-video video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}