.m-input-base {
    height: 40px;
    width: 100%;
    font-family: 'JosefinSans', sans-serif;
    font-weight: 300;
    font-size: var(--font-size-l3);
    color: var(--light-1);
    background-color: var(--darker);
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--dark);
    outline: none;
    padding: 8px;
    transition: background-color var(--bg-transition-duration), color var(--bg-transition-duration), border-color var(--bg-transition-duration);
}

.m-input-base:not(:disabled):hover {
    border: solid 1px var(--light-1);
}

.m-input-base:active {
    border-color: var(--theme-color);
}

.m-input-base:focus {
    color: var(--lightest);
    border-color: var(--lightest);
}

.m-input-base:invalid {
    color: var(--theme-color);
    border-color: var(--theme-color);
}

.m-input-base:disabled {
    background: var(--light-gray);
    color: var(--dark-gray);
}

.m-input-base::placeholder {
    font-size: var(--font-size-l4);
    color: var(--light-gray);
}

.m-input-base:hover::placeholder {
    color: var(--lightest);
}

.m-input-base:focus::placeholder {
    color: transparent;
}

.m-input-base:-webkit-autofill {
    background-color: var(--darker) !important;
    -webkit-text-fill-color: var(--light-1) !important;
    font-family: 'JosefinSans', sans-serif;
    font-weight: 300;
    font-size: var(--font-size-l3);
    border-color: var(--dark) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--darker) inset;
    box-shadow: 0 0 0 1000px var(--darker) inset;
    transition: background-color var(--bg-transition-duration), color var(--bg-transition-duration), border-color var(--bg-transition-duration) !important;
}

.m-input-base:-webkit-autofill:hover {
    border-color: var(--light-1) !important;
}

.m-input-base:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--lightest) !important;
    border-color: var(--lightest) !important;
}

.m-input-base:-webkit-autofill:disabled {
    -webkit-text-fill-color: var(--dark-gray) !important;
    background: var(--light-gray) !important;
}

@media (max-width: 600px) {
    .m-input-base {
        font-size: var(--font-size-l3-mobile);
    }

    .m-input-base::placeholder {
        font-size: var(--font-size-l4-mobile);
    }

    .m-input-base:-webkit-autofill {
        font-size: var(--font-size-l3-mobile);
    }
}