.m-firebase-login-state {
    margin-left: auto;
    height: 32px;
}

.m-firebase-login-state:hover span {
    color: var(--lightest);
}

.m-firebase-login-state #userInformation {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.m-firebase-login-state svg {
    object-fit: contain;
    height: 32px;
    display: block;
    margin-left: 7px;
    transition: color 0.6s, filter 0.6s;
}

.m-firebase-login-state #displayNameLabel {
    display: inline-block;
    height: 100%;
    line-height: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: var(--font-size-l4);
}

.m-firebase-login-state .user-icon path:first-child {
    fill: var(--light-1);
    transition: fill 0.18s ease;
}

.m-firebase-login-state .user-icon circle {
    fill: none;
    stroke: var(--light-1);
    transition: stroke 0.18s ease;
}

.m-firebase-login-state .user-icon.filled circle {
    stroke: var(--darkest);
    fill: var(--theme-color);
    transition: stroke 0.18s ease;
}

.m-firebase-login-state .user-icon.filled circle:nth-child(2) {
    stroke: var(--darkest);
}

.m-firebase-login-state .user-icon path:last-child {
    fill: none;
    stroke: var(--light-1);
    transition: stroke 0.18s ease;
}

.m-firebase-login-state .user-icon.filled path:last-child {
    stroke: var(--darkest);
}

.m-firebase-login-state:hover .user-icon {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
    -webkit-filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
}

.m-firebase-login-state:hover .user-icon path:first-child {
    fill: var(--theme-color);
}

.m-firebase-login-state:hover .user-icon circle {
    stroke: var(--theme-color);
}

.m-firebase-login-state:hover .user-icon path:last-child {
    stroke: var(--theme-color);
}

.m-firebase-login-state:hover .user-icon.filled circle:nth-child(2) {
    stroke: var(--darkest);
}

.m-firebase-login-state:hover .user-icon.filled path:last-child {
    stroke: var(--darkest);
}

@media (max-width: 600px) {
    .m-firebase-login-state #displayNameLabel {
        font-size: var(--font-size-l4-mobile);
    }
}