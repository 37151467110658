.m-title {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: var(--padding-l2);
}

.m-title h3 {
    font-weight: 400;
    font-size: var(--font-size-l2);
    margin: 0;
}

.m-title img {
    height: 36px;
    margin: 0 10px 0 0;
}

@media (max-width: 600px) {
    .m-title h3 {
        font-size: var(--font-size-l2-mobile);
    }
}