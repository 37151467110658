.m-navigation {
    list-style: none;
    padding: 0;
    display: flex;
    background: transparent;
}

.m-navigation ul {
    padding: 0;
}

.m-navigation li {
    display: inline-block;
    min-width: 105px;
    padding: 10px 10px;
    transition: background-color var(--bg-transition-duration), box-shadow var(--bg-transition-duration), padding var(--bg-transition-duration);
    border: 1px solid transparent;
    border-radius: 2px 2px 0 0;
    cursor: pointer;
}

.m-navigation a {
    text-align: center;
}

.m-navigation li:last-child {
    margin-right: 0;
}

.m-navigation a.active > li {
    background-color: var(--theme-color);
    border: 1px solid var(--dark);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

.m-navigation a:hover > li {
    background-color: var(--theme-color);
    border: 1px solid var(--darker);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.m-navigation a:hover li {
    color: var(--darkest);
}

.m-navigation li {
    font-size: 16px;
    font-weight: 300;
    color: var(--light-1);
    text-decoration: none;
}

.m-navigation a.active li {
    font-weight: 400;
    color: var(--darkest);
}

#btnToggleNavigation {
    display: none;
    margin-left: 6px;
}

.m-navigation.mobile {
    display: none;
    position: fixed;
    background-color: var(--dark);
    height: 100%;
    width: 100%;
    top: 57px;
    left: 0;
    margin: 0;
}

.m-navigation.mobile .header-text {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.m-navigation.mobile .header-text h1 {
    font-weight: 400;
}

.m-navigation.mobile hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--dark-gray);
    padding: 0;
    margin: 8px 12px;
}

@media (max-width: 680px) {
    .m-navigation {
        display: none;
        position: fixed;
        background-color: var(--dark);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
    }

    .m-navigation.mobile.shown {
        display: block;
    }

    .m-navigation ul {
        display: flex;
        flex-direction: column;
    }

    .m-navigation a li {
        min-width: 100%;
        border: 0;
        border-radius: 0;
    }

    .m-navigation a.active li {
        border: none;
    }

    .m-navigation a:hover li {
        border: none;
    }

    .m-navigation a li {
        font-size: 16px;
    }

    #btnToggleNavigation {
        display: flex;
    }
}