.m-captcha {
}

.m-captcha #captchaImage {
    background: var(--light-1);
    padding: 0;
    border: 1px solid var(--dark);
    border-bottom: 0;
}

.m-captcha #captchaImage canvas {
    height: 72px;
    display: block;
    margin: auto;
}

.m-captcha #captchaInput {
    display: flex;
    width: 100%;
}

.m-captcha #captchaInput .m-input-base {
    border-radius: 0;
    width: 100%;
}

.m-captcha #captchaInput .m-button {
    border-radius: 0;
    border-left: none;
    flex-grow: 1;
}

.m-captcha #captchaInput .m-button:hover {
    border-color: var(--dark);
}

.m-captcha #captchaInput .m-button:nth-child(2) {
    padding: 5px;
}

.m-captcha #captchaInput .m-button:nth-child(2) img {
    margin: 4px;
    transition: filter 0.2s;
}

.m-captcha #captchaInput .m-button:nth-child(2) {
    max-width: 36px;
    min-width: 36px;
    flex-grow: 0;
}