.m-parameter {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--padding-l3);
}

.m-parameter:last-child {
    margin-bottom: 0;
}

.m-parameter label:nth-child(1) {
    display: inline-block;
    font-size: var(--font-size-l3);
    font-weight: 400;
    margin-bottom: var(--padding-l4);
}

.m-parameter a {
    color: var(--light-1);
}

@media (max-width: 600px) {
    .m-parameter label:nth-child(1) {
        font-size: var(--font-size-l3-mobile);
        margin-bottom: var(--padding-l4-mobile);
    }
}