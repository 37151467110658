.m-panel.m-firebase-user-management-panel {
    min-width: 480px;
}

.m-firebase-user-management-panel .userManageLinks {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.m-firebase-user-management-panel #actionErrorText {
    margin-top: 14px;
    color: var(--theme-color);
    font-size: 14px;
}

.m-firebase-user-management-panel #actionErrorText p:first-child {
    font-weight: 500;
}

.m-firebase-user-management-panel hr {
    display: block;
    padding: 0;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (max-width: 500px) {
    .m-panel.m-firebase-user-management-panel {
        min-width: 100%;
    }
}