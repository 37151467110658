.m-cookie-panel {
    transition: bottom 0.4s ease;
}

.m-cookie-panel h1 {
    font-size: var(--font-size-l1);
    margin-bottom: 8px;
}

.m-cookie-panel.overlay {
    background: var(--darkest);
    bottom: 12px;
    position: fixed;
    z-index: 100;
    padding: 14px;
    text-align: justify;
    border: 1px solid var(--darker-gray);
    border-radius: 2px;
    box-shadow: 0 0 12px rgba(10, 10, 10, 1);
    max-width: 640px;
    left: 50%;
    transform: translate(-50%, 0);
}

.m-cookie-panel.overlay.document-end-reached {
    bottom: 65px;
}

@media (max-width: 640px) {
    .m-cookie-panel h1 {
        font-size: var(--font-size-l1-mobile);
    }

    .m-cookie-panel.overlay {
        width: 100%;
        left: unset;
        transform: unset;
    }
}