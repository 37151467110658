.m-media-frame .m-image {
    border: 1px solid var(--darker);
    border-radius: 4px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    margin-top: 6px;
}

.m-media-frame .m-video {
    border: 1px solid var(--darker);
    border-radius: 2px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    margin-top: 6px;
}