.m-footer {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: var(--darker);
    border-top: solid 1px var(--darker-gray);
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.3);
    flex-grow: 0;
    flex-shrink: 0;
}

.m-footer .copyright {
    font-size: 12px;
    font-weight: 200;
    color: var(--light-1);
    text-align: center;
}

.m-footer hr {
    display: block;
    padding: 0;
    max-width: 230px;
    margin: 5px auto 7px;
}

.m-footer-links {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: var(--font-size-l3);
    font-weight: 300;
}

.m-footer-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.m-footer-links a {
    color: var(--light-1);
    text-decoration: none;
    cursor: pointer;
}

.m-footer-links a + a {
    margin-left: 14px;
}

@media (max-width: 680px) {
    .m-footer hr {
        max-width: 100%;
    }
}