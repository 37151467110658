.m-hamburger {
    width: 30px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.m-hamburger div {
    height: 2px;
    background-color: var(--light-1);
    border-radius: 1px;
    transition: background-color 0.3s, transform 0.3s;
}

.m-hamburger:hover div {
    background-color: var(--theme-color);
}

.m-hamburger:hover div:nth-child(1) {
    transform: scaleX(75%);
}

.m-hamburger:hover div:nth-child(3) {
    transform: scaleX(75%);
}