.m-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--dark);
    border-bottom: solid 1px var(--darker-gray);
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.3);
    transition: background-color 0.5s;
    z-index: 10;
}

.m-header #headerElements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px;
}

/* Dieser Selektor ist wichtig, da blur das Layout zerstört, wenn das Mobile-Menü offen ist  */
.m-header.page-scrolled:not(:has(.m-navigation.mobile.shown)) {
    background-color: rgba(65, 65, 65, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}