.m-formatted-text-content h1 {
    margin-bottom: 10px;
}

.m-formatted-text-content h2, h3 {
    margin-bottom: 6px;
}

.m-formatted-text-content h2 {
    margin-top: 34px;
}

.m-formatted-text-content h3 {
    margin-top: 12px;
}

.m-formatted-text-content h3 + p {
    margin-top: 6px;
}

.m-formatted-text-content ul {
    padding: 0;
    margin: 6px 0 20px 12px;
}

.m-formatted-text-content ul li {
    font-size: var(--font-size-l3);
    font-weight: 400;
    line-height: 1.45;
    text-align: justify;
}

.m-formatted-text-content.marketing ul li {
    font-size: calc(var(--font-size-l3) + 1px);
}

.m-formatted-text-content ul li + li {
    margin-top: 6px;
}

.m-formatted-text-content p {
    text-align: justify;
    font-size: var(--font-size-l3);
    line-height: 1.5;
    font-weight: 200;
}

.m-formatted-text-content.marketing p {
    font-size: calc(var(--font-size-l3) + 1px);
}

.m-formatted-text-content p + h3, ul + p {
    margin-top: 18px;
}

.m-formatted-text-content a {
    color: var(--light-1);
    text-decoration: none;
    font-weight: 400;
    margin-top: 10px;
    display: inline-block;
    transition: color 0.2s;
}

.m-formatted-text-content a:hover {
    color: var(--theme-color);
}

@media (max-width: 680px) {
    .m-formatted-text-content p {
        font-size: var(--font-size-l3-mobile);
    }

    .m-formatted-text-content.marketing p {
        font-size: calc(var(--font-size-l3-mobile) + 1px);
    }

    .m-formatted-text-content ul li {
        font-size: var(--font-size-l3-mobile);
    }

    .m-formatted-text-content.marketing ul li {
        font-size: calc(var(--font-size-l3-mobile) + 1px);
    }
}