.m-table {
    border-collapse: collapse;
    font-size: var(--font-size-l3);
}

.m-table tr {
    cursor: pointer;
    transition: background-color var(--bg-transition-duration), color var(--bg-transition-duration);
}

.m-table th, .m-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.m-table tbody tr:hover {
    background-color: var(--theme-color);
    color: var(--dark);
    cursor: pointer;
}

.m-table tr.selected {
    background-color: var(--theme-color);
    color: var(--dark);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.m-table tr.selected td:first-child {
    border-radius: 2px 0 0 2px;
}

.m-table tr.selected td:last-child {
    border-radius: 0 2px 2px 0;
}

.m-table thead td {
    padding: 12px 10px;
}

.m-table tbody td {
    padding: 10px;
}

.m-table thead tr {
    background-color: var(--dark-gray);
}

.m-table thead tr {
    border: solid 1px var(--darker-gray);
    border-left: 0;
    border-right: 0;
}

.m-table thead td {
    min-width: 110px;
    font-weight: 500;
}

.m-table tbody td {
    font-weight: 300;
}

.m-table tbody td:hover {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.m-table tbody tr {
    border-bottom: solid 1px var(--darker-gray);
}

.m-table tbody .no-data-hint-row {
    font-size: 12px;
    height: 42px;
    font-weight: 200;
    color: var(--theme-color);
}

.m-table tfoot tr {
    background-color: var(--darker);
}

.m-table tfoot td {
    font-size: 14px;
    color: rgb(120, 120, 120);
    font-weight: 300;
}

@media (max-width: 600px) {
    .m-table {
        font-size: var(--font-size-l3-mobile);
    }
}