.m-flex-panel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 30px;
}

@media (max-width: 680px) {
    .m-flex-panel {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}