.m-table-pagination {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: var(--darker);
    border: 1px solid var(--darker-gray);
    border-top: none;
    padding: var(--padding-l2);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0 0 2px 2px;
}

.m-table-pagination div:first-child {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.m-table-pagination div:first-child .m-button {
    width: 36px;
    max-width: 36px;
    height: 36px;
    max-height: 36px;
    min-height: 0;
    min-width: 0;
    padding: 0;
    font-size: var(--font-size-l4);
    background: transparent;
}

.m-table-pagination #currentPageLabel {
    display: inline-block;
    font-size: 12px;
    text-align: center;
    width: 36px;
    height: 36px;
}

.m-table-pagination #currentPageLabel span {
    line-height: 36px;
    font-weight: 500;
    font-size: 13px;
}

.m-table-pagination #totalTableItemsLabel {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
}