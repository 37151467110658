@font-face {
    font-family: 'Luckiest Guy';
    src: url('./../../assets/fonts/LuckiestGuy-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.main-title-container {
    font-family: 'Luckiest Guy', sans-serif;
    text-align: center;
    margin: auto auto;
}

.main-title-container p {
    width: 100%;
    box-sizing: border-box;
    text-shadow: rgba(0, 0, 0, 0.8) 4px 4px 12px;
}

.main-title-container p:nth-child(1) {
    font-size: calc(100vw / 5);
    color: var(--lightest);
}

.main-title-container p:nth-child(2) {
    font-size: calc(140vw / 5);
    line-height: 0.54;
    color: var(--theme-color);
}

.description-part {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.description-part:not(:last-child) {
    margin-bottom: 100px;
}

.video-and-text {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    gap: 2px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1450px) {
    .main-title-container p:nth-child(1) {
        font-size: 300px;
    }

    .main-title-container p:nth-child(2) {
        font-size: 420px;
    }
}

@media (max-width: 1200px) {
    .sub-title {
        font-size: 22px;
    }

    .description-part:not(:last-child) {
        margin-bottom: 100px;
    }
}
