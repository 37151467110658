.coin-rain {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -3;
}

.coin {
    position: absolute;
    top: -62px;
    width: 62px;
    height: auto;
    animation: fall 8s linear infinite;
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.4));
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.coin.fade-in {
    opacity: 1;
}

.coin.fade-out {
    opacity: 0;
}

@keyframes fall {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh);
    }
}

@media (max-width: 1600px) {
    .coin {
        width: 52px;
    }
}

@media (max-width: 1400px) {
    .coin {
        width: 42px;
    }
}

@media (max-width: 1200px) {
    .coin {
        width: 32px;
    }
}

@media (max-width: 600px) {
    .coin {
        width: 26px;
    }
}