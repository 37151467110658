.m-link {
    display: inline-block;
    font-size: var(--font-size-l4);
    font-weight: 300;
    text-decoration: none;
    color: var(--lightest);
    cursor: pointer;
    transition: color 0.2s;
}

.m-link:hover {
    color: var(--theme-color);
}

@media (max-width: 600px) {
    .m-link {
        font-size: var(--font-size-l4-mobile);
    }
}