.m-filter-area {
    background-color: var(--dark);
    border: 1px solid var(--darker-gray);
    padding: var(--padding-l2);
    border-radius: 2px 2px 0 0;
}

.m-filter-area .filters {
    margin-top: var(--padding-l3);
    display: flex;
    gap: 13px;
    flex-wrap: wrap;
}

.m-filter-area .m-parameter {
    flex-grow: 1;
    margin: 0;
    min-width: 140px;
}

.m-filter-area .filter-preview {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    font-weight: 200;
    margin-left: 8px;
    font-size: 13px;
    cursor: pointer;
    overflow-x: hidden;
}

.m-filter-area .filter-preview .no-filters-hint {
    font-size: 13px;
    color: var(--lighter-gray);
}

.m-filter-area .filter-preview div span:first-child {
    font-weight: 500;
}

.m-filter-area .filter-preview div span:nth-child(2) {
    margin-left: 6px;
    font-weight: 200;
    color: var(--lighter-gray);
}

.m-filter-area .filter-area-shortcut-buttons {
    display: flex;
    gap: 8px;
}

.m-filter-area .filter-area-shortcut-button {
    height: 32px;
    width: 32px;
    min-width: 32px;
    min-height: 32px;
    padding: 6px;
    border-radius: 4px;
}

.m-filter-area #btnChangeVisibility img {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
    -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
}