.m-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    z-index: 1000;
}

.m-dialog {
    background: var(--darker);
    padding: var(--padding-l2);
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--darker-gray);
    max-width: 400px;
    width: 100%;
}

.m-dialog h2 {
    font-size: var(--font-size-l2);
    margin-bottom: 10px;
}

.m-dialog-content {
    font-size: var(--font-size-l3);
    margin-bottom: 10px;
}

@media (max-width: 680px) {
    .m-dialog h2 {
        font-size: var(--font-size-l2-mobile);
    }

    .m-dialog-content {
        font-size: var(--font-size-l3-mobile);
    }
}