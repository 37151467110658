.m-button-bar {
    display: flex;
}

.m-button-bar.expand button {
    flex: 1;
}

.m-button-bar button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
}

.m-button-bar button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.m-button-bar + .m-button-bar button {
    border-top-width: 0;
}