.m-hint {
    font-size: var(--font-size-l3);
    display: block;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.3);
}

.m-hint.bordered {
    padding: 8px 8px;
    border: 1px solid var(--light-1);
    border-radius: 4px;
}

.m-hint.bordered.default {
    background-color: var(--darker);
    border-color: var(--darker);
}

.m-hint.nice {
    color: var(--signal-color-green);
}

.m-hint.bordered.nice {
    background-color: var(--signal-color-green);
    color: var(--dark);
    border-color: var(--dark);
}

.m-hint.warning {
    color: var(--theme-color);
}

.m-hint.bordered.warning {
    background-color: var(--theme-color);
    color: var(--dark);
    border-color: var(--dark);
}

.m-hint.error {
    color: var(--signal-color-red);
}

.m-hint.bordered.error {
    background-color: var(--signal-color-red);
    color: var(--light-1);
    border-color: var(--light-1);
}

.m-hint.in-progress {
    color: var(--light-gray);
}

.m-hint.bordered.in-progress {
    background-color: var(--dark-gray);
    color: var(--light-gray);
    border-color: var(--gray);
}

.m-hint span {
    line-height: 1.34;
    display: inline-block;
}

.m-hint div:nth-child(1) span {
    font-weight: 400;
    white-space: nowrap;
}

.m-hint div:nth-child(2) span {
    font-weight: 300;
}

@media (max-width: 600px) {
    .m-hint {
        font-size: var(--font-size-l3-mobile);
    }
}