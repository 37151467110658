.m-panel {
    background-color: var(--dark);
    padding: var(--padding-l2);
    display: block;
    max-width: 540px;
    min-width: 380px;
    border: 1px solid var(--darker-gray);
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.3);
}

.m-panel.no-padding {
    padding: 0;
}

.m-panel hr:nth-child(2) {
    margin-top: 12px;
    margin-bottom: 16px;
}

@media (max-width: 680px) {
    .m-panel {
        width: 100%;
        max-width: 720px;
        min-width: 200px;
    }
}