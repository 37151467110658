@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 700;
    src: url("assets/fonts/JosefinSans-BoldItalic.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 700;
    src: url("assets/fonts/JosefinSans-Bold.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 600;
    src: url("assets/fonts/JosefinSans-SemiBoldItalic.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 600;
    src: url("assets/fonts/JosefinSans-SemiBold.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 500;
    src: url("assets/fonts/JosefinSans-MediumItalic.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 500;
    src: url("assets/fonts/JosefinSans-Medium.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/JosefinSans-Regular.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 300;
    src: url("assets/fonts/JosefinSans-LightItalic.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 300;
    src: url("assets/fonts/JosefinSans-Light.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 200;
    src: url("assets/fonts/JosefinSans-ExtraLight.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 200;
    src: url("assets/fonts/JosefinSans-ExtraLight.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: italic;
    font-weight: 100;
    src: url("assets/fonts/JosefinSans-Thin.ttf");
}

@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 100;
    src: url("assets/fonts/JosefinSans-Thin.ttf");
}

* {
    margin: 0;
}

html, body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
}

html {
    font-family: 'JosefinSans', sans-serif;
}

body {
    background-color: var(--darkest);
    color: var(--light-1);
    font-size: var(--font-size-l3);
}

hr {
    display: block;
    height: 1px;
    border: none;
    background: linear-gradient(to right,
    rgba(0, 0, 0, 0) 0px,
    var(--dark-gray) 30px,
    var(--dark-gray) calc(100% - 30px),
    rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

#root {
    height: 100%;
}

p, span, a {
    font-weight: 300;
    line-height: 1.4;
}

h1 {
    font-size: var(--font-size-l0);
    font-weight: 500;
}

h2 {
    font-size: var(--font-size-l1);
    font-weight: 500;
}

h3 {
    font-size: var(--font-size-l2);
    font-weight: 500;
}

h4 {
    font-size: var(--font-size-l3);
    font-weight: 500;
}

@media (max-width: 600px) {
    body {
        font-size: var(--font-size-l3-mobile);
    }

    .h1 {
        font-size: var(--font-size-l0-mobile);
    }

    .h2 {
        font-size: var(--font-size-l1-mobile);
    }

    .h3 {
        font-size: var(--font-size-l2-mobile);
    }

    .h4 {
        font-size: var(--font-size-l3-mobile);
    }
}