::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-track {
    background: var(--darker);
    transition: background-color 0.5s ease;
}

::-webkit-scrollbar-track:hover {
    background: var(--dark);
}

::-webkit-scrollbar-thumb {
    background: var(--light-gray);
    border-radius: 4px;
    border: 3px solid var(--darker);
    transition: background-color 0.5s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--theme-color);
    border-color: var(--darker);
    cursor: pointer;
}