.m-scroll-reveal {
}

.m-scroll-reveal .hidden-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.75s ease-in-out;
}

.m-scroll-reveal .hidden-content.visible {
    opacity: 1;
    transform: translateY(0);
}