.m-loading-watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
    pointer-events: none;
}

@keyframes watermarkColorShift {
    0% {
        background-position: 0 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.m-loading-watermark span {
    display: inline-block;
    font-size: 14px;
    color: var(--light-gray);

    background: linear-gradient(
            90deg,
            var(--light-gray) 0%,
            var(--light-1) 25%,
            var(--lightest) 50%,
            var(--light-1) 75%,
            var(--light-gray) 100%
    );

    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: watermarkColorShift 10s ease-in-out infinite;
    will-change: background-position;
}