.m-push-message-container {
    position: fixed;
    bottom: 18px;
    left: 18px;
    display: flex;
    gap: 10px;
    z-index: 1000;
}

.m-push-message-container.document-end-reached {
    bottom: 82px;
}

@media (max-width: 680px) {
    .m-push-message-container {
        padding: var(--padding-l2-mobile);
        width: 100%;
        bottom: 0;
        left: 0;
        flex-direction: column;
    }

    .m-push-message-container.document-end-reached {
        bottom: 64px;
    }
}